import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import './SideMenu.scss';

/* eslint jsx-a11y/anchor-is-valid: "off" */

// 'permissionName 'icon', 'route', 'label': {}
export const menuItems = {
  student__student__students__Customer: {
    students__Customers: {},
    'csr/new-registration/new__Register Customers': {},
    'customer-new-registration__New Registration': {},
    'transfer-students__Transfer Students': {},
    'refund-students__Refund Students': {},
    'customer-drop-off-registration__Drop Off Registration': {},
    'waiting-for-training__Customers Waiting To Schedule': {},
    'waiting-for-road-test__Customers Waiting For Road Test': {},
  },
  instructors__instructors__instructors__Instructors: {},
  'csr_smart_scheduler__csr__csr/dashboard__CSR & Smart Scheduler': {
    'csr/new-registration__New Registrations': {},
    'csr/lead-qualification__Lead Qualification': {},
    'csr/drop-off-registration__Drop Off Registration': {},
    'csr/collection-report__Collection Report': {},
    'csr/rta-test-request__Rta Test Request': {},
    'csr/appointments__Appointments': {},
    'csr/customer-feedbacks__Customer Feedbacks': {},
    'csr/external-leads__External Leads': {},
  },
  'cashier__csr__cashier/dashboard__Cashier': {
    'cashier/collect-payment__Collect Payment': {},
  },
  'test_booking__csr__test_booking/dashboard__Test Booking': {
    // 'cashier/collect-payment__Collect Payment': {},
  },
  'supervisor_instructor__supervisor-instructor__supervisor-instructors__Supervisor & Instructor': {
    'supervisor/dashboard__Dashboard': {},
    'supervisor/stage-report__Customers': {},
    'supervisor/leave__Leave Summary': {},
    'supervisor/schedules__Schedules': {},
    'supervisor/upload-training-info__Update Training Info': {},
    'supervisor/change-instructor-of-schedules__Change Instructor Schedules': {},
    'supervisor/instructor-performance-review__Upload Instructor Performance Review': {},
    'supervisor/instructor-fines-upload__Upload Instructor Fines': {},
    'supervisor/instructor-performance-review-report__Instructor Performance Review Report': {},
    // 'supervisor/instructor-fines-report__Upload Instructor Fines': {},
    'supervisor/training-schedule__Training Schedule': {},
    'supervisor/backup-instructor-availability-generator__BackUp Training Schedule': {},
    'supervisor/backup-instructor-schedule-report__BackUp Training Report': {},
    'supervisor/backup-instructor-schedule-reports__BackUp Training Schedule Reports': {},
    'supervisor/absent-from-cts__Asbsent classes from CTS': {},
    'instructor-rating__Instructor Rating': {
      'instructor-rating/top-rated-instructors__Top Rated Instructors': {},
      'instructor-rating/customer-rating-of-instructor__Customer Ratings': {},
      'instructor-rating/customer-feedback-of-instructor__Customer Feedback': {},
    },
    'supervisor/lecture-hall-schedule__Lecture Hall Schedule': {},
    'supervisor/mock-test-schedule__Mock Test Device Schedule': {},
    'supervisor/instructor-incentive-yard__Instructor Yard Incentive': {},
    'supervisor/instructor-incentive-road__Instructor Road Incentive': {},
    'supervisor/instructor-incentive-processed__Instructor Incentive Processed': {},
    'supervisor/simulator-training-schedule__Simulator Training Schedule': {},
    'supervisor/upload-rta-test-result__Upload RTA Test Results': {},
  },
  'corporate__corporate-user__corporate__Corporate': {},
  promotion__promotions__promotion__Promotions: {},
  'payment_report__payment-report__payment-reports__Payment Report': {},
  'admin_user__admin-user__admin-users__Admin User': {},
  'course_curriculum__widgets__null__Course & Curriculum': {
    'course-rule-management__Course Rule Management': {},
    courses__Courses: {},
    'course-types__Course Types': {},
    'course-type-incentive__Course Type Incentive': {},
    'fee-codes__Fee Master': {},
    'lecture-theory__Theory Lectures': {},
    'lecture-hall__Lecture Hall': {},
    yards__Yards: {},
    'license-type__License Types': {},
    'working-hours__Working Hours': {
      'working-hours/practical-training__Practical Training': {},
      'working-hours/theory-training__Theory Training': {},
      'working-hours/mock-test__Mock Test': {},
      'working-hours/simulator-training__Simulator Training': {},
      'time-slot__Time Slot': {},
      'instructor-type__Instructor Type': {},
    },
    'error-code__Error Code Manage': {
      'error-code-category__Error Code Category': {},
      'error-code-training__Training Topic': {},
      'error-codes__Error Code': {},
      'error-codes/upload-error-codes__Upload Error Codes': {},
      'error-code-training/upload-training-topic__Upload Training Topics': {},
    },
    instances__Instance: {},
    'lumpsum-milestone-splitup__Lumpsum Milestone Splitup': {},
  },
  'system_configuration__system-configuration__system-configurations__System Configuration': {
    'document-type__Document Type': {},
    'country-group__Country Group': {},
    'branch-types__Branch Types': {},
    'low-credit-settings__Low Credit Settings': {},
    branches__Branches: {},
    languages__Languages: {},
    'ris-languages__RIS Languages': {},
    'tax-classes__Tax Classes': {},
    'ramadan-settings__Ramadan Settings': {},
    'schedule-configurations__Schedule Configurations': {},
    holidays__Holidays: {},
    contract__Contract: {},
    'appointment-settings__Appointment Settings': {},
    'knowledge-base__Knowledge Base': {},
    'api-role-permissions__Api Role Permissions': {},
    'api-permissions__Api Permissions': {},
    'ticket-type-assignments__Ticket Type Assignments': {},
    'app-version-update__App Version Update': {},
    'absenteesim-configurations__Absenteesim Configurations': {},
  },

  'cms_templates__file__cms-templates__CMS Templates': {
    'email-templates__Email Templates': {},
    'notification-templates__Notification Templates': {},
    'sms-templates__Sms Templates': {},
  },

  'integrator__system-configuration__integrator__Integrator DI': {
    'cts-sanityLog__SanityLog': {},
    'cts-StudentPermitDetails__StudentPermitDetails': {},
    'integrator-dashboard__Dashboard': {},
    'integrator-logs__Integrator Logs': {},
    'integrator-traininginfo__Training Info': {},
    'integrator-rtapayments__RTA Payments': {},
    'integrator-paymentclearance__Payment Clearance': {},
    'cts-studentprofileinfo__CTS Customer ProfileInfo': {},
    'cts-scheduleclass__CTS Schedule Class': {},
    'cts-lectureattendanceinfo__CTS Lecture AttendanceInfo': {},
    'cts-assessmentbooking__CTS Assessment Booking': {},
    'integrator-trainingdata__Training Data': {},
    'cts-assessmentresult__CTS Assessment Result': {},
    'cts-attendanceresult__CTS Attendance Result': {},
    'cts-instructoralert__CTS Instructor Alert': {},
    'di-studentcdd__Customer CDD': {},
    'di-examdetails__Exam Details': {},
    'di-examresult__Exam Result': {},
    'di-reschedulewarningmessage__Reschedule Warning': {},
  },
  'system_log__file__system-logs__System Logs': {
    'additional-training-log__Additional Training Log': {},
    'download-history__Download History': {},
    'sms-log__Sms Log': {},
    'utilization-log__Utilization Log': {},
    'api-access-logs__Api Access Logs': {},
    'student-utilization-log__Student Utilization Log': {},
  },
  // integrator__calendar__schedules__Schedules: {},
  'report__payment-report__report__Reports': {
    'report/instructor-utilization__Instructor utilization Report': {},
    'report/backup-instructor-utilization__Instructor backup utilization Report': {},
    'report/registration__Registration Report': {},
    'report/test__Test Report': {},
    'report/mock/test__Mock Test Report': {},
    'report/instructor-api__Instructor Api Report': {},
    'report/student__Student Report': {},
    'report/student/without-learning-permit-number__Student Without Learning Permit Number Report': {},
    'report/test-schedule__Test Schedule Report': {},
    'report/assessment-schedule__Assessment Schedule Report': {},
    'report/instructor__Instructor Report': {},
    'report/lecture-attendance__Lecture Attendance Report': {},
    'report/examiner-performance-report__Examiner Performance Report': {},
    'report/online-lecture-attendance__Online Lecture Attendance Report': {},
    'report/instructor-passing__Instructor Passing Report': {},
    'report/instructor-passing-performance__Overall Instructor Passing Performance Report': {},
    'report/first-attempt-passing-performance__First Attempt – Instructor Passing Performance Report': {},
    'report/first-attempt-assessor-passing-performance__First Attempt – Assessor Passing Performance Report': {},
    'report/internal-examiner-performance__Internal Examiner Performance Report': {},
    'report/upgraded-students__Upgraded Students Report': {},
    'report/zoho-report__Zoho Report': {},
    'report/addonpayment-report__AddOn Payment Report': {},
    'report/promocode-report__Promocode Report': {},
    'report/golden-opportunity-report__Golden Opportunity Report': {},
    'report/whatsapp-broadcast-report__Whatsapp Boradcast Report': {},
    'report/student-utilization-report__Student Utilization Report': {},
    'report/custom-payment-report__Payment Report': {},
  },
  tickets__bill__tickets__Tickets: {},
  'message_center__student__message-center__Message Center': {
    videos__Videos: {},
    messages__Messages: {},
    'message_templates__Message Templates': {},
  },
};

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.isMenuOpen = this.isMenuOpen.bind(this);
    this.state = {
      showMenu: false,
      menuOpen: this.isMenuOpen(props),
    };
    this.menuToggle = null;
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleMenuOpen = this.toggleMenuOpen.bind(this);
  }

  isMenuOpen(props) {
    return Object.keys(menuItems).find((menuKey) =>
      Object.keys(menuItems[menuKey])
        .map((menuItem) => {
          const itemInfo = menuItem.split('__');
          return props.location.pathname.includes(itemInfo[0]);
        })
        .find((item) => item)
    );
  }

  componentDidMount() {
    this.menuToggle = document.getElementById('menuToggle');
    this.menuToggle.addEventListener('click', this.toggleMenu);

    if (
      this.props.user &&
      this.props.user.userType === 'agent-user' &&
      menuItems.student__student__students__Customer
    ) {
      delete menuItems.student__student__students__Customer.students__Customers;
    } else {
      delete menuItems.student__student__students__Customer[
        'csr/new-registration/new__Register Customers'
      ];
      delete menuItems.student__student__students__Customer[
        'customer-drop-off-registration__Drop Off Registration'
      ];
    }
  }

  componentWillUnmount() {
    this.menuToggle.removeEventListener('click', this.toggleMenu);
  }

  toggleMenuOpen(menuOpen) {
    this.setState({ menuOpen });
  }

  toggleMenu() {
    this.setState((state) => ({
      showMenu: !state.showMenu,
    }));
  }

  render() {
    const { adminUIPermissions = [], user } = this.props;
    console.log('admin per', adminUIPermissions);

    const uiPermissions = adminUIPermissions.length
      ? adminUIPermissions.map((t) => t.key)
      : [];

    return (
      <>
        <div className={`sidemenu ${this.state.showMenu ? 'active' : ''}`}>
          <div
            className={`sidemenu__close ${this.state.showMenu ? 'active' : ''}`}
            onClick={this.toggleMenu}
            role="presentation"
          >
            <span className="close">
              <span className="close__icon" />
            </span>
          </div>
          <ul className={`menu ${this.state.menuOpen ? 'menu--open' : ''}`}>
            <li className="menu__item">
              <Link to="/" className="menu__link">
                <i className="menu__icon icon-dasbhoard" /> Dashboards
              </Link>
            </li>
            {Object.keys(menuItems)
              .filter((menuItem) => {
                const itemInfo = menuItem.split('__');
                return (
                  // permissionKeys.length === 0 ||
                  // permissionKeys.includes(itemInfo[0])
                  uiPermissions.length === 0 ||
                  uiPermissions.includes(itemInfo[0])
                );
              })
              .map((menuItem) => {
                const itemInfo = menuItem.split('__');
                return (
                  <MenuItemLevel1
                    // key={this.props.location.pathname}
                    pathname={this.props.location.pathname}
                    toggleActive={this.toggleMenuOpen}
                    icon={itemInfo[1]}
                    link={itemInfo[2]}
                    label={itemInfo[3]}
                    childItems={menuItems[menuItem]}
                    key={itemInfo[2]}
                    user={user}
                    adminUIPermissions={adminUIPermissions}
                    mainItem={itemInfo[0]}
                  />
                );
              })}
          </ul>
        </div>
        <div
          className={`black-drop ${this.state.showMenu ? 'active' : ''}`}
          onClick={this.toggleMenu}
          role="presentation"
        />
      </>
    );
  }
}

SideMenu.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({}),
  adminUIPermissions: PropTypes.arrayOf(PropTypes.shape({})),
};

SideMenu.defaultProps = {
  permissions: null,
  adminUIPermissions: [],
};

class MenuItemLevel1 extends React.Component {
  constructor(props) {
    super(props);
    this.toggleActive = this.toggleActive.bind(this);
    this.isActive = this.isActive.bind(this);
    this.thisElement = null;
    this.submenu = null;
    this.state = {
      pathname: props.pathname,
      active: this.isActive(props),
    };
  }

  isActive(props) {
    return Object.keys(props.childItems)
      .map((menuItem) => {
        const itemInfo = menuItem.split('__');
        return props.pathname.includes(itemInfo[0]);
      })
      .find((item) => item);
  }

  toggleActive(active) {
    this.setState({
      active,
    });
    this.props.toggleActive(active);
  }

  render() {
    const subMenuPermission = this.props.adminUIPermissions.find(
      (menu) => menu.key === this.props.mainItem
    );
    const subMenu = subMenuPermission ? subMenuPermission.subMenu : [];

    if (!Object.keys(this.props.childItems).length) {
      return (
        <li className="menu__item">
          <Link to={`/${this.props.link}`} className="menu__link">
            <i className={`menu__icon icon-${this.props.icon}`} />{' '}
            {this.props.label}
          </Link>
        </li>
      );
    }
    const myMenuStyle = {
      padding: 0,
    };
    return (
      <li
        className={`menu__item ${
          this.state.active ? 'menu__item--active' : ''
        }`}
        ref={(element) => {
          this.thisElement = element;
        }}
      >
        {this.props.icon === 'csr' ? (
          <Link to={`/${this.props.link}`} className="menu__link">
            <i className={`menu__icon icon-${this.props.icon}`} />{' '}
            {this.props.label}
          </Link>
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <a className="menu__link" onClick={() => this.toggleActive(true)}>
            <i className={`menu__icon icon-${this.props.icon}`} />{' '}
            {this.props.label}
          </a>
        )}

        <div
          className="submenu"
          ref={(element) => {
            this.submenu = element;
          }}
        >
          <div className="back-link">
            <button
              className="link link__witharrow"
              onClick={() => this.toggleActive(false)}
            >
              <i className="icon-arrow-left" />
              Back to Main Menu
            </button>
          </div>
          {/* level 2 submenu head */}
          <div className="submenu__head">
            {this.props.icon === 'csr' ? (
              <Link
                to={`/${this.props.link}`}
                className="menu__link"
                style={myMenuStyle}
              >
                <i className={` icon-${this.props.icon} menu__icon`} />{' '}
                {this.props.label}
              </Link>
            ) : (
              <>
                <i className={` icon-${this.props.icon} menu__icon`} />{' '}
                {this.props.label}
              </>
            )}
          </div>
          <ul className="submenu__list">
            {Object.keys(this.props.childItems).map((menuItem) => {
              const itemInfo = menuItem.split('__');
              const active = this.state.pathname.includes(itemInfo[0]);

              return (
                <MenuItemLevel2
                  // active
                  // key={this.state.pathname}
                  pathname={this.state.pathname}
                  link={itemInfo[0]}
                  label={itemInfo[1]}
                  childItems={this.props.childItems[menuItem]}
                  key={itemInfo[0]}
                  subMenu={subMenu}
                />
              );
            })}
          </ul>
        </div>
      </li>
    );
  }
}

MenuItemLevel1.propTypes = {
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  childItems: PropTypes.shape({}),
  pathname: PropTypes.string.isRequired,
};

MenuItemLevel1.defaultProps = {
  childItems: [],
};

class MenuItemLevel2 extends React.Component {
  constructor(props) {
    super(props);
    this.toggleActive = this.toggleActive.bind(this);
    this.thisElement = null;
  }

  componentDidMount() {}

  toggleActive() {
    const element = this.thisElement;
    if (element) {
      const classes = element.className.split(' ');
      const activeClass = classes.indexOf('menu__item--active');

      if (activeClass === -1) {
        element.className = classes.join(' ');
        element.className += ' menu__item--active';
      } else {
        classes.splice(activeClass, 1);
        element.className = classes.join(' ');
      }
    }
  }

  render() {
    const { link, subMenu } = this.props;
    const hasPermission = !!subMenu.find((me) => me.key === link);
    if (
      !Object.keys(this.props.childItems).length &&
      (!subMenu.length || hasPermission)
    ) {
      return (
        <li className="menu__item menu__item--submenu">
          <Link
            to={`/${this.props.link}`}
            className="menu__link menu__link--light"
          >
            {this.props.label}
          </Link>
        </li>
      );
    }

    if (!subMenu.length || hasPermission) {
      return (
        <li
          className={`menu__item menu__item--submenu ${
            this.props.pathname.includes(this.props.link)
              ? ' menu__item--active'
              : ''
          }`}
          ref={(element) => (this.thisElement = element)}
        >
          <>
            <a
              className="menu__link menu__link--light"
              onClick={this.toggleActive}
              role="button"
              tabIndex="0"
            >
              {this.props.label}
            </a>
            <ul className="submenu__items">
              {Object.keys(this.props.childItems).map((menuItem) => {
                const itemInfo = menuItem.split('__');
                return (
                  <MenuItemLevel3
                    pathname={this.props.pathname}
                    link={itemInfo[0]}
                    label={itemInfo[1]}
                    childItems={this.props.childItems[menuItem]}
                    key={itemInfo[0]}
                  />
                );
              })}
            </ul>
          </>
        </li>
      );
    }
    return <li className="menu__item menu__item--submenu"> </li>;
  }
}

MenuItemLevel2.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  // /* eslint react/forbid-prop-types: off  */
  childItems: PropTypes.shape({}),
  pathname: PropTypes.string.isRequired,
};

MenuItemLevel2.defaultProps = {
  childItems: [],
};

const MenuItemLevel3 = (props) => {
  return (
    <li
      className={`menu__item menu__item--submenu-items ${
        props.pathname.includes(props.link) ? ' menu__item--active' : ''
      }`}
    >
      <Link to={`/${props.link}`} className="menu__link menu__link--light">
        {props.label}
      </Link>
    </li>
  );
};

MenuItemLevel3.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default withRouter((props) => <SideMenu {...props} />);
